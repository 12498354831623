import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import ScannerIndicator from '@/features/Scanner/ScannerIndicator'
import withScanner from '@/features/Scanner/withScanner'
import useLayout from '@/layout/useLayout'
import { CURRENCY_SIGN } from '@/lib/helpers'

import useCheckout from '../../hooks/useCheckout'
import Step from '../Step/Step'
import { CartEmpty, CartFooter, CartSummary, CartTable } from './Cart.styles'
import CartItem from './CartItem'

const Cart = () => {
  const { cart, cartTotal, setCurrentStep } = useCheckout()
  const { setShowBack } = useLayout()
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (cart.length === 0) {
    navigate('/')
  }

  useEffect(() => {
    setShowBack(true)

    return () => {
      setShowBack(false)
    }
  }, [])

  return (
    <Step>
      {cart.length > 0 && (
        <>
          <CartTable>
            {cart.map(product => (
              <CartItem key={product.id} product={product} />
            ))}
          </CartTable>
          <ScannerIndicator size="small" />
          <CartFooter>
            <CartSummary>
              <dt>{t('total')}</dt>
              <dd>
                {CURRENCY_SIGN}
                {cartTotal.toFixed(2)}
              </dd>
            </CartSummary>
          </CartFooter>
        </>
      )}
      {cart.length === 0 && <CartEmpty>{t('cartIsEmpty')}</CartEmpty>}
    </Step>
  )
}

export default withScanner(Cart)
