import { useNavigate } from 'react-router'

import { CURRENCY_SIGN } from '@/lib/helpers'

const { ProductItemWrapper, ProductItemImage, ProductItemContent, ProductItemTitle, ProductItemPrice } = require('./ProductItem.styles')

const ProductItem = ({ title, imageUrl, price, id }) => {
  const navigate = useNavigate()
  return (
    <ProductItemWrapper onClick={() => navigate(`/product/${id}`)}>
      <ProductItemImage>
        <img src={imageUrl} alt={title} />
      </ProductItemImage>
      <ProductItemContent>
        <ProductItemTitle>{title}</ProductItemTitle>
        <ProductItemPrice>
          {CURRENCY_SIGN}
          {price.toFixed(2)}
        </ProductItemPrice>
      </ProductItemContent>
    </ProductItemWrapper>
  )
}

export default ProductItem
