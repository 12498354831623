import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import View from '@/layout/View'

import Summary from '../Summary'
import Cart from './components/Cart'
import Confirmation from './components/Confirmation'
import Payment from './components/Payment'
import useCheckout from './hooks/useCheckout'

const CheckoutFooter = () => {
  const { currentStep, setCurrentStep, paymentMethod, cartTotal, reset } = useCheckout()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const resetCheckout = () => {
    reset()
    navigate('/')
  }

  const handlePayment = () => {
    if (paymentMethod === 'CARD') {
      try {
        window.BtDevice.startPayment(cartTotal)
      } catch (error) {
        console.error('[DEVICE] startPayment error', error)
      }
    } else {
      setCurrentStep('CONFIRMATION')
    }
  }

  if (currentStep === 'CART') {
    return (
      <Button variant="primary" onClick={() => setCurrentStep('PAYMENT')} appendIcon="angle-right">
        {t('goNext')}
      </Button>
    )
  } else if (currentStep === 'PAYMENT') {
    return (
      <Button variant="primary" onClick={() => handlePayment()} appendIcon="angle-right">
        {t('payNow')}
      </Button>
    )
  } else if (currentStep === 'CONFIRMATION') {
    return (
      <Button block={true} onClick={() => resetCheckout()}>
        {t('close')}
      </Button>
    )
  }
}

const Checkout = () => {
  const { currentStep, steps } = useCheckout()
  const { t } = useTranslation()

  return (
    <View
      steps={steps}
      currentStep={currentStep}
      showClose
      showBack
      collapsibleComponent={currentStep === 'PAYMENT' && <Summary />}
      collapsibleTitle={t('orderSummary')}
      footer={<CheckoutFooter />}
    >
      <AnimatePresence mode="wait">
        {currentStep === 'CART' && <Cart key="CART" />}
        {currentStep === 'PAYMENT' && <Payment key="PAYMENT" />}
        {currentStep === 'CONFIRMATION' && <Confirmation key="CONFIRMATION" />}
      </AnimatePresence>
    </View>
  )
}

export default Checkout
