import { motion } from 'framer-motion'
import { forwardRef } from 'react'

import { LoaderObject } from './Loader.styles'

const Loader = forwardRef((props, ref) => {
  const { center } = props
  return (
    <LoaderObject as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} ref={ref} center={center}>
      <span></span>
      <span></span>
      <span></span>
    </LoaderObject>
  )
})

Loader.displayName = 'Loader'

export default Loader
