import styled from 'styled-components'

import { TransitionPrimary } from '@/lib/mixins'

export const TopBarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: var(--size-top-bar);
  justify-content: flex-end;
  padding: 0 0.75rem;
  background-color: rgba(0, 0, 0, 0.15);
`

export const TopBarTool = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 0.75rem;
  }

  svg {
    height: 0.85rem;
    display: block;
    fill: currentColor;
    ${TransitionPrimary('fill')}

    #wifi {
      > * {
        opacity: 0.25;

        &:nth-child(-n + ${props => props.level}) {
          opacity: 1;
        }
      }
    }

    #battery {
      #fill {
        transform: scaleX(${props => props.level / 100});
        transform-origin: 2px center;
      }
    }
  }

  span {
    text-transform: uppercase;
    font-size: 0.5rem;
    margin-left: 0.25rem;
    font-weight: 600;
    letter-spacing: 0.1em;
  }
`

export const TopBarTime = styled.div`
  ${TransitionPrimary('color')}
  text-transform: uppercase;
  font-size: 0.655rem;
  margin-left: 0.25rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-right: auto;
`
