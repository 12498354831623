import styled from 'styled-components'

export const ConfirmationWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ConfirmationIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: var(--color-light);
  margin: 0 auto 1.5rem;
  svg {
    width: 50%;
    stroke: var(--color-secondary);
  }
`

export const ConfirmationContent = styled.div`
  text-align: center;
  max-width: 20rem;
  margin: auto;

  h1 {
    margin-bottom: 0.5rem;
  }

  p {
    font-family: var(--font-secondary);
    opacity: 0.5;
    margin-bottom: 0;
  }
`

export const ConfirmationFooter = styled.div`
  margin-top: auto;
  padding-bottom: 1rem;
`

export const ConfirmationVendor = styled.div`
  display: flex;
  font-size: 0.85rem;
  color: var(--color-muted);
  font-weight: 500;
  margin-bottom: 1.5rem;
  justify-content: center;
  align-items: center;
  svg {
    height: 1rem;
    width: 3rem;
    margin-left: 0.25rem;
    fill: var(--color-primary);
  }
`
