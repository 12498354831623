import { createContext, useReducer } from 'react'

import PropTypes from '@/lib/propTypes'

const defaultState = {
  theme: 'LIGHT',
  hideLogo: false,
  showBack: false,
  showClose: false
}

export const LayoutContext = createContext(defaultState)

export const LayoutReducer = (state, action) => {
  switch (action.type) {
    case 'SET_THEME':
      return {
        ...state,
        theme: action.payload
      }
    case 'SET_SHOW_BACK':
      return {
        ...state,
        showBack: action.payload
      }
    case 'SET_HIDE_LOGO':
      return {
        ...state,
        hideLogo: action.payload
      }
    case 'SET_SHOW_CLOSE':
      return {
        ...state,
        showClose: action.payload
      }
    default:
      return state
  }
}

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LayoutReducer, defaultState)
  return <LayoutContext.Provider value={{ state, dispatch }}>{children}</LayoutContext.Provider>
}

LayoutProvider.propTypes = {
  children: PropTypes.node
}
