import styled from 'styled-components'

export const ProductItemWrapper = styled.div`
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-line);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
`
export const ProductItemImage = styled.div`
  flex: 0 0 5rem;
`
export const ProductItemContent = styled.div`
  flex: 1 1 auto;
  padding-left: 1.5rem;
`
export const ProductItemTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`
export const ProductItemPrice = styled.span`
  font-weight: 500;
  color: var(--color-primary);
`
