import { motion, useAnimation, useDragControls } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import useCheckout from '@/features/Checkout/hooks/useCheckout'

import useLayout from '../useLayout'
import {
  ViewBody,
  ViewBodyOverlay,
  ViewCollapse,
  ViewCollapseBody,
  ViewCollapseBodyInner,
  ViewCollapseDrag,
  ViewCollapseTitle,
  ViewFooter,
  ViewScroller,
  ViewSteps,
  ViewStepsItem,
  ViewWrapper
} from './View.styles'

const View = ({ children, theme, cover, steps, showBack, showClose, collapsibleComponent, collapsibleTitle, hideLogo, footer }) => {
  const { setTheme, setShowBack, setShowClose, setHideLogo } = useLayout()
  const { t } = useTranslation()
  const controls = useAnimation()
  const { currentStep } = useCheckout()
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [collapseHeight, setCollapseHeight] = useState(0)
  const collapseBodyRef = useRef()
  const collapseTitleRef = useRef()
  const scrollerRef = useRef()
  const location = useLocation()

  const dragControls = useDragControls()

  const handleDragEnd = (event, info) => {
    const shouldOpen = info.velocity.y > 300 || (info.velocity.y >= 0 && info.point.y > 300)

    if (shouldOpen) {
      controls.start('visible')
      setIsCollapsed(false)
    } else {
      controls.start('hidden')
      setIsCollapsed(true)
    }
  }

  const hideCollapse = () => {
    controls.start('hidden')
    setIsCollapsed(true)
  }

  useEffect(() => {
    controls.start('hidden')
    setIsCollapsed(true)
  }, [currentStep])

  useEffect(() => {
    if (theme) setTheme(theme)
    setHideLogo(!!hideLogo)
    setShowBack(!!showBack)
    setShowClose(!!showClose)
  }, [])

  useEffect(() => {
    if (collapsibleComponent) {
      setCollapseHeight(collapseBodyRef.current.clientHeight - collapseTitleRef.current.clientHeight)
    }
  }, [collapsibleComponent])

  useEffect(() => {
    console.log(scrollerRef.current?.scrollTo(0, 0))
  }, [location])

  return (
    <ViewWrapper
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      hasCollapse={collapsibleComponent}
      theme={theme}
    >
      {steps && (
        <ViewSteps>
          {steps.map((step, index) => (
            <ViewStepsItem
              active={steps.findIndex(o => o.name === currentStep) === index}
              done={steps.findIndex(o => o.name === currentStep) > index}
              key={index}
            >
              <span>{t(step.title)}</span>
            </ViewStepsItem>
          ))}
        </ViewSteps>
      )}
      {collapsibleComponent && (
        <ViewCollapse as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <ViewCollapseTitle ref={collapseTitleRef}>{collapsibleTitle}</ViewCollapseTitle>
          <ViewCollapseBody
            as={motion.div}
            ref={collapseBodyRef}
            drag="y"
            initial="hidden"
            onDragEnd={handleDragEnd}
            dragListener={false}
            animate={controls}
            transition={{
              type: 'spring',
              damping: 40,
              stiffness: 300
            }}
            variants={{
              visible: { y: collapseHeight },
              hidden: { y: 0 }
            }}
            dragConstraints={{ top: 0, bottom: collapseHeight }}
            dragElastic={0.2}
            dragControls={dragControls}
            collapsed={isCollapsed}
          >
            <ViewCollapseBodyInner collapsed={isCollapsed}>{collapsibleComponent}</ViewCollapseBodyInner>
            <ViewCollapseDrag onPointerDown={e => dragControls.start(e)} style={{ touchAction: 'none' }}></ViewCollapseDrag>
          </ViewCollapseBody>
        </ViewCollapse>
      )}
      <ViewScroller ref={scrollerRef}>
        <ViewBody cover={cover} hasCollapse={!!collapsibleComponent} hasSteps={steps} hasFooter={!!footer}>
          {children}
          <ViewBodyOverlay show={!isCollapsed} onClick={() => hideCollapse()} />
        </ViewBody>
      </ViewScroller>
      {footer && <ViewFooter>{footer}</ViewFooter>}
    </ViewWrapper>
  )
}

export default View
