import { motion } from 'framer-motion'
import { createPortal } from 'react-dom'

import Icon from '@/components/Icon'

import { ModalBackdrop, ModalBody, ModalClose, ModalDialog, ModalHeader, ModalWrapper } from './Modal.styles'

const Modal = props => {
  const { children, onClose, header, variant = 'default' } = props

  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    return onClose ? onClose() : null
  }

  return createPortal(
    <ModalWrapper as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.1 }}>
      <ModalBackdrop onClick={handleClose}></ModalBackdrop>
      <ModalDialog
        as={motion.div}
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{ type: 'tween' }}
        variant={variant}
      >
        <ModalClose onClick={handleClose}>
          <Icon name="close"></Icon>
        </ModalClose>
        {header && <ModalHeader>{header}</ModalHeader>}
        <ModalBody>{children}</ModalBody>
      </ModalDialog>
    </ModalWrapper>,
    document.querySelector('#modal')
  )
}

Modal.displayName = 'Modal'

export default Modal
