import styled from 'styled-components'

import { Reset } from '@/lib/mixins'

import { ScannerIndicatorWrapper } from '../Scanner/Scanner.styles'

export const ProductWrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 1rem;
`

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1.25rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  height: 12rem;
  background-color: var(--color-light);

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`

export const ProductHeader = styled.div`
  margin-bottom: 1.5rem;
  padding: 0.5rem 0;
`

export const ProductTitle = styled.h1`
  font-size: 1.3rem;
  margin-bottom: 0.35rem;
`

export const ProductContent = styled.div`
  h2 {
    margin-bottom: 1rem;
  }
  h6 {
    margin-bottom: 0.25rem;
  }
`

export const ProductPrice = styled.h3`
  font-size: 2.5rem;
  color: var(--color-primary);
  margin-bottom: 0;

  sup {
    vertical-align: bottom;
    font-size: 1rem;
    position: relative;
    top: -1rem;
    margin-left: 0.5rem;
    color: var(--color-muted);
  }
`

export const ProductEmpty = styled.div`
  max-width: 14rem;
  margin: 20vh auto;
  text-align: center;
  opacity: 0.5;
`
