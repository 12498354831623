import { useState } from 'react'
import { Collapse } from 'react-collapse'

import Icon from '../Icon'

const { PanelWrapper, PanelHeader, PanelContent, PanelIndicator, PanelTitle } = require('./Panel.styles')

const Panel = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <PanelWrapper>
      <PanelHeader onClick={() => setIsOpen(!isOpen)}>
        <PanelTitle>{title}</PanelTitle>
        <PanelIndicator isOpened={isOpen}>
          <Icon name="angle-down" />
        </PanelIndicator>
      </PanelHeader>
      <Collapse isOpened={isOpen}>
        <PanelContent>{children}</PanelContent>
      </Collapse>
    </PanelWrapper>
  )
}

export default Panel
