import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import View from '@/layout/View'

import useCheckout from '../Checkout/hooks/useCheckout'
import { ScannerFooter, ScannerLogo, ScannerVendor, ScannerWrapper } from './Scanner.styles'
import ScannerIndicator from './ScannerIndicator'
import withScanner from './withScanner'

const Scanner = () => {
  const { t } = useTranslation()
  const { cart } = useCheckout()
  const navigate = useNavigate()

  return (
    <View theme="DARK" hideLogo={true}>
      <ScannerWrapper>
        <ScannerLogo>
          <img src="img/logo-full.svg" alt="" />
        </ScannerLogo>
        <ScannerIndicator />
        <ScannerFooter>
          {cart.length > 0 && (
            <Button block onClick={() => navigate('/checkout')} badge={cart.length}>
              {t('goToCheckout')}
            </Button>
          )}
        </ScannerFooter>
        <ScannerVendor>
          <span>Powered by</span>
          <img src="/img/berg-pay.svg" alt="" />
        </ScannerVendor>
      </ScannerWrapper>
    </View>
  )
}

export default withScanner(Scanner)
