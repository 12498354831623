import styled from 'styled-components'

export const SummaryWrapper = styled.div``

export const SummaryTable = styled.div`
  margin-bottom: 0.75rem;
`
export const SummaryDetail = styled.div`
  font-size: 1rem;
  text-align: right;

  dd {
    min-width: 6rem;
  }
`
export const SummaryTotal = styled.div`
  font-size: 1.1rem;
  border-top: 1px solid var(--color-line);
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  text-align: right;

  dt {
    font-size: 0.8em;
    vertical-align: middle;
  }
  dd {
    min-width: 6rem;
  }
`

export const SummaryItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-line);
  margin-bottom: 0.5rem;
`

export const SummaryItemImage = styled.div`
  flex: 0 0 3rem;

  img {
    display: block;
    width: 100%;
  }
`

export const SummaryItemDetails = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  h6 {
    margin-bottom: 0.2rem;
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 0;
  }
`

export const SummaryEmpty = styled.div`
  max-width: 14rem;
  margin: 20vh auto;
  text-align: center;
  opacity: 0.5;
`

export const SummaryItemPrice = styled.h3`
  margin-bottom: 0;
  flex: 0 0 5rem;
  text-align: right;
  font-size: 1rem;
  margin-right: auto;
  font-weight: 500;

  > span {
    color: var(--color-muted);
    margin-right: 0.25em;
    letter-spacing: 0.1em;
    font-weight: 300;
  }
`
